.App {
  background-color: #000;
  min-height: 100vh;
}

.App-logo {
  pointer-events: none;
  height: 60px;
  margin-right: 15px;
}

.App-header {
  color: white;
  padding: 20px;
  font-size: 80px;
  font-weight: 300;
}

.csv-input {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
